<template>
  <div class="content">
    <canvas ref="background" class="background"></canvas>
    <canvas
      id="camera-feed"
      ref="canvas"
      style="max-width: inherit; max-height: inherit"
    ></canvas>

    <NavSidebar
      :showHelperArrows="showHelperArrows"
      :skipAnimations="skipAnimations"
      :quizModeActive="quizModeActive"
      @show-net="showNet"
      @toggleHelperArrows="toggleHelperArrows"
      @toggleSkipAnimations="(checked) => (skipAnimations = checked)"
    />

    <img
      v-if="!quizModeActive && !cameraEnabled"
      src="./assets/quiz-icon.svg"
      :width="45"
      class="quiz-icon"
      @click="startQuiz"
      alt="quiz-icon"
    />
    <img
      v-if="!quizModeActive"
      src="./assets/ar-symbol.svg"
      :width="45"
      class="ar-symbol"
      @click="toggleCameraFeed"
      alt="ar-symbol"
    />

    <QuizComponent
      v-if="quizModeActive"
      :net="net"
      @end="
        quizModeActive = false;
        disableAnimationControls = false;
      "
      @enable-controls="disableAnimationControls = false"
      @change-net="showRandomNet()"
    />

    <AnimationController
      :currentStep="step"
      :numberOfSteps="nSteps"
      :disableAnimationControls="disableAnimationControls"
      @next="next"
      @prev="prev"
    />
  </div>
</template>

<script>
import ARMixin from "./ARMixin.js";
import { getCurrentInstance } from "vue";
import { GLTFLoader } from "three/addons/loaders/GLTFLoader.js";
import { OrbitControls } from "three/addons/controls/OrbitControls.js";
import NavSidebar from "./components/NavSidebar.vue";
import AnimationController from "./components/AnimationController.vue";
import QuizComponent from "./components/QuizComponent.vue";

import {
  Scene,
  WebGLRenderer,
  AxesHelper,
  PerspectiveCamera,
  AnimationMixer,
  LoopOnce,
  AmbientLight,
  DirectionalLight,
  Vector3,
  Clock,
  Group,
} from "three";

const UPDATE_INTERVAL = 1000 / 60;
const CAM_POS = [5, 10, 5];

function createCamera(bg) {
  //const aspect = window.innerHeight / window.innerWidth; // corresponds to aspect ratio used in wasm
  const aspect = bg.offsetHeight / bg.offsetHeight; // same aspect ratio as camera feed (background)
  const camera = new PerspectiveCamera(45, aspect, 0.1, 10000);

  // camera looks down the negative z-axis
  // console.log("Camera is at position:", camera.position);

  camera.position.set(CAM_POS[0], CAM_POS[1], CAM_POS[2]);
  camera.lookAt(0, 0, 0);
  camera.updateProjectionMatrix();
  camera.updateMatrixWorld();

  const dir = new Vector3();
  camera.getWorldDirection(dir);
  // console.log("Camera looks in direction:", dir);

  // console.log("Projection matrix", camera.projectionMatrix);

  // Debug the projection function in wasm:
  // console.log("view matrix", camera.matrixWorldInverse);
  // console.log("matrix world", camera.matrixWorld);
  // const test = new Vector3(0.5, 0, 0);
  // test.project(camera);
  // console.log("projected", test);
  // const widthHalf = window.innerWidth / 2;
  // const heightHalf = window.innerHeight / 2;
  // test.x = test.x * widthHalf + widthHalf;
  // test.y = -(test.y * heightHalf) + heightHalf;
  // console.log("on screen", test.x, test.y, test.z);

  return camera;
}

const Direction = {
  Forward: "forward",
  Backward: "backward",
};

const FORWARD_TIME = 2;
const BACKWARD_TIME = 3;

export default {
  name: "App",
  components: { NavSidebar, AnimationController, QuizComponent },
  mixins: [ARMixin],
  data: () => ({
    net: 0,
    shouldPlayAnim: false,
    lastShoudlPlayAnim: false,
    isPlaying: false,
    step: 0,
    nSteps: 0,
    currentTime: 0,
    arrows: new Array(5).fill(null),
    showHelperArrows: true,
    skipAnimations: false,
    quizModeActive: false,
    disableAnimationControls: false,
    video: undefined,
    videoContext: undefined,
    videoCanvas: undefined,
  }),
  created() {
    this.instance = getCurrentInstance();
    this.instance.proxy.$scene = new Scene();
    window.addEventListener("resize", this.initializeCanvas);
  },
  unmounted() {
    window.removeEventListener("resize", this.initializeCanvas);
  },
  async mounted() {
    this.rootCamera = createCamera(this.$refs.background);
    this.renderer = new WebGLRenderer({
      alpha: true,
      canvas: this.$refs.canvas,
    });
    this.initializeCanvas();
    const controls = new OrbitControls(
      this.rootCamera,
      this.renderer.domElement
    );
    controls.maxPolarAngle = Math.PI / 2 - 0.2;
    controls.maxDistance = 20;
    controls.minDistance = 5;

    this.showNet(this.net);

    //this.renderer.render(proxy.$scene, this.rootCamera);
    this.renderer.setClearColor(0x000000, 0);
    this.animate();
  },
  methods: {
    initializeCanvas() {
      const size = window.innerHeight;
      this.$refs.background.height = size;
      this.$refs.background.width = size;
      this.renderer.setSize(size, size);
    },
    playAnimation(direction, clock) {
      if (this.skipAnimations) {
        this.mixer.setTime(this.step);
        return;
      }
      const delta = clock.getDelta();
      if (direction === Direction.Forward) {
        if (this.mixer.time + FORWARD_TIME * delta < this.step) {
          this.mixer.update(FORWARD_TIME * delta);
          setTimeout(() => {
            this.playAnimation(direction, clock);
          }, UPDATE_INTERVAL);
        } else {
          this.mixer.setTime(this.step);
        }
      } else {
        if (this.mixer.time - BACKWARD_TIME * delta > this.step) {
          this.mixer.update(-BACKWARD_TIME * delta);
          setTimeout(() => {
            this.playAnimation(direction, clock);
          }, UPDATE_INTERVAL);
        } else {
          this.mixer.setTime(this.step);
        }
      }
      this.renderer.render(this.instance.proxy.$scene, this.rootCamera);
    },
    prev() {
      if (this.step === 0 || this.isPlaying || this.disableAnimationControls) {
        return;
      }
      this.isPlaying = true;
      if (this.step < this.arrows.length && this.arrows[this.step]) {
        this.arrows[this.step].visible = false;
      }
      this.step--;
      this.playAnimation(Direction.Backward, new Clock());
      setTimeout(
        () => {
          if (this.showHelperArrows && this.arrows[this.step]) {
            this.arrows[this.step].visible = true;
          }
          this.isPlaying = false;
        },
        this.skipAnimations ? 10 : 1000 / BACKWARD_TIME
      );
    },
    next() {
      if (
        this.step === this.nSteps ||
        this.isPlaying ||
        this.disableAnimationControls
      ) {
        return;
      }
      this.isPlaying = true;
      if (this.arrows[this.step]) {
        this.arrows[this.step].visible = false;
      }
      this.step++;
      this.playAnimation(Direction.Forward, new Clock());
      setTimeout(
        () => {
          if (
            this.showHelperArrows &&
            this.step < this.arrows.length &&
            this.arrows[this.step]
          ) {
            this.arrows[this.step].visible = true;
          }
          this.isPlaying = false;
        },
        this.skipAnimations ? 10 : 1000 / FORWARD_TIME
      );
    },
    startQuiz() {
      this.step = 0;
      this.quizModeActive = true;
      this.showRandomNet();
    },
    showRandomNet() {
      this.disableAnimationControls = true;
      const isMirrored = Math.random() < 0.5;
      const showNoNet = Math.random() < 0.5;
      if (showNoNet) {
        // no-nets indices are from 100 to 111
        this.showNet(Math.floor(Math.random() * 12) + 100, isMirrored);
      } else {
        this.showNet(Math.floor(Math.random() * 11), isMirrored);
      }
    },
    async toggleCameraFeed() {
      if (this.cameraEnabled) {
        this.track && this.track.stop();
        this.cameraEnabled = false;
        setTimeout(() => {
          const context = this.$refs.background.getContext("2d");
          context.clearRect(
            0,
            0,
            this.$refs.background.width,
            this.$refs.background.height
          );
          this.rootCamera.position.set(CAM_POS[0], CAM_POS[1], CAM_POS[2]);
          this.rootCamera.lookAt(0, 0, 0);
        }, 200); // wait for camera feed to stop
        this.animate();
      } else {
        this.cameraEnabled = true;

        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
          try {
            const stream = await navigator.mediaDevices.getUserMedia({
              video: {
                facingMode: "environment",
                width: { ideal: 2560 },
                height: { ideal: 2560 },
                frameRate: {
                  ideal: 8,
                },
              },
            });
            this.video = document.createElement("video");
            this.video.srcObject = stream;
            await this.video.play();
            this.videoCanvas = document.createElement("canvas");
            this.videoContext = this.videoCanvas.getContext("2d");
            this.videoCanvas.width = 2560;
            this.videoCanvas.height = 2560;
            this.captureFrame();
          } catch (err) {
            console.error("An error occurred: " + err);
          }
        } else {
          console.error("getUserMedia not supported");
        }
      }
    },
    async captureFrame() {
      while (this.video && this.cameraEnabled) {
        console.log("Captured frame");
        this.videoContext.drawImage(this.video, 0, 0, 2560, 2560);
        this.currentImage = await createImageBitmap(this.videoCanvas);
        await this.arHandler.predictImage(
          this.currentImage,
          this.prevPosition,
          this.prevLookAt
        );
      }
    },
    animate() {
      if (!this.cameraEnabled) {
        requestAnimationFrame(this.animate);
        this.renderer.render(this.instance.proxy.$scene, this.rootCamera);
      }
    },
    toggleHelperArrows(checked) {
      this.showHelperArrows = checked;
      this.arrows[this.step].visible = checked;
    },
    showNet(net, isMirrored = false) {
      this.step = 0;
      this.net = net;
      const { proxy } = this.instance;
      proxy.$scene = new Scene();
      const mirrorMultiplier = isMirrored ? -1 : 1;

      const light = new AmbientLight();
      light.intensity = 2;
      proxy.$scene.add(light);
      const spotLight = new DirectionalLight(0xffffff, 0.5);
      spotLight.position.set(5, 8, 5);
      proxy.$scene.add(spotLight);
      const spotLight2 = new DirectionalLight(0xffffff, 0.5);
      spotLight2.position.set(-5, 8, -5);
      proxy.$scene.add(spotLight2);
      const spotLight3 = new DirectionalLight(0xffffff, 0.5);
      spotLight3.position.set(-5, 8, 5);
      proxy.$scene.add(spotLight3);
      const spotLight4 = new DirectionalLight(0xffffff, 0.5);
      spotLight4.position.set(5, 8, -5);
      proxy.$scene.add(spotLight4);

      // const geometry = new SphereGeometry(0.5, 32, 32);
      // const material = new MeshBasicMaterial({ color: 0x00ff00 });
      // const circle = new Mesh(geometry, material);
      // circle.position.set(0, 0, 0);
      // circle.rotation.set(0, Math.PI / 2, 0);
      // proxy.$scene.add(circle);

      if (window.location.hostname === "localhost") {
        const axesHelper = new AxesHelper(5);
        proxy.$scene.add(axesHelper);
      }

      // clean up scene
      proxy.$scene.children.forEach((child) => {
        if (child instanceof Group) {
          proxy.$scene.remove(child);
        }
      });

      // initialize net
      const loader = new GLTFLoader();
      let netPath;
      if (this.net < 100) {
        this.nSteps = 5;
        netPath = `/nets/net_${String(net + 1).padStart(2, "0")}/`;
      } else {
        const noNetNr = net % 100;
        switch (noNetNr) {
          case 0:
          case 2:
          case 3:
          case 4:
          case 6:
          case 7:
            this.nSteps = 5;
            break;
          case 1:
          case 5:
          case 9:
          case 10:
          case 11:
            this.nSteps = 4;
            break;
          case 8:
            this.nSteps = 6;
            break;
          default:
            console.error("No net found for number", noNetNr);
        }

        netPath = `/nets/no_net_${String(noNetNr + 1).padStart(2, "0")}/`;
      }
      loader.load(netPath + "net.glb", (gltf) => {
        gltf.scene.scale.set(1, 1, mirrorMultiplier);
        proxy.$scene.add(gltf.scene);
        this.mixer = new AnimationMixer(gltf.scene);
        gltf.animations.forEach((clip) => {
          const action = this.mixer.clipAction(clip);
          action.reset();
          action.setLoop(LoopOnce);
          action.clampWhenFinished = true;
          action.play();
        });
        gltf.tick = (delta) => this.mixer.update(delta);
        this.renderer.render(proxy.$scene, this.rootCamera);
      });

      loader.load(netPath + "grid.glb", (grid) => {
        grid.scene.scale.set(1, 1, mirrorMultiplier);
        proxy.$scene.add(grid.scene);
        this.renderer.render(proxy.$scene, this.rootCamera);
      });

      // Show arrows
      this.arrows = new Array(this.nSteps).fill(null);
      loader.load(netPath + "step_1_arrow.glb", (arrow) => {
        arrow.scene.scale.set(1, 1, mirrorMultiplier);
        proxy.$scene.add(arrow.scene);
        if (!this.showHelperArrows) {
          arrow.scene.visible = false;
        }
        this.arrows[0] = arrow.scene;
        this.renderer.render(proxy.$scene, this.rootCamera);
      });
      for (let i = 2; i <= this.nSteps; i++) {
        loader.load(netPath + `step_${i}_arrow.glb`, (arrow) => {
          arrow.scene.scale.set(1, 1, mirrorMultiplier);
          proxy.$scene.add(arrow.scene);
          arrow.scene.visible = false;
          this.arrows[i - 1] = arrow.scene;
          this.renderer.render(proxy.$scene, this.rootCamera);
        });
      }
    },
  },
};
</script>

<style>
body {
  margin: 0;
  font-family: "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  overflow: hidden;
}
.content {
  margin: auto;
}
#camera-feed {
  position: fixed;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}
.background {
  position: fixed;
  z-index: -1;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}
.ar-symbol {
  position: fixed;
  top: 23px;
  right: 23px;
  z-index: 2;
  cursor: pointer;
}
.quiz-icon {
  position: fixed;
  top: 23px;
  right: 91px;
  z-index: 2;
  cursor: pointer;
}
</style>
