<template>
  <div class="accordion">
    <div class="header" v-on:click="toggle">
      <slot name="header"></slot>
      <img
        src="../assets/chevron.svg"
        alt="chevron"
        class="header-icon"
        :style="{ rotate: show ? '270deg' : '180deg' }"
      />
    </div>
    <transition
      name="accordion"
      v-on:before-enter="beforeEnter"
      v-on:enter="enter"
      v-on:before-leave="beforeLeave"
      v-on:leave="leave"
    >
      <div class="body" v-show="show">
        <div class="body-inner">
          <slot></slot>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
// adapted from https://codepen.io/ktsn/pen/dzNRjx
export default {
  name: "ExpandableContent",
  props: {
    showInitial: { type: Boolean, default: false },
  },
  data() {
    return {
      show: this.showInitial,
    };
  },

  methods: {
    toggle: function () {
      this.show = !this.show;
    },
    beforeEnter: function (el) {
      el.style.height = "0";
    },
    enter: function (el) {
      el.style.height = el.scrollHeight + "px";
    },
    beforeLeave: function (el) {
      el.style.height = el.scrollHeight + "px";
    },
    leave: function (el) {
      el.style.height = "0";
    },
  },
};
</script>

<style scoped>
.accordion {
  max-width: 400px;
  margin-bottom: 20px;

  background-color: #fefefe;
}

.accordion .header {
  height: 50px;
  line-height: 50px;
  padding: 0 40px 0 8px;
  position: relative;
  cursor: pointer;
}

.accordion .header:hover {
  background-color: #f0f0f0;
}

.accordion .header-icon {
  position: absolute;
  top: 22px;
  right: 18px;
  width: 6px;
  transition-duration: 0.3s;
}

.accordion .body {
  background-color: #fff;
  border-top: 0;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  transition: 150ms ease-out;
}

.accordion .body-inner {
  padding: 8px;
  overflow-wrap: break-word;
}

.accordion .header-icon.rotate {
  transform: rotate(180deg);
  transition-duration: 0.3s;
}
</style>
