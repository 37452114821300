<template>
  <div>
    <div :class="['sidebar', isSidebarActive ? '' : 'sidebar-hidden']">
      <div class="sidebar-content">
        <div class="sidebar-header">
          <div id="titel-bar">
            <img src="../assets/logo.png" alt="logo" />
            <h1>CubeNet</h1>
          </div>
        </div>
        <div class="sidebar-body">
          <Switch
            label="Show Helper Arrows"
            :checked="showHelperArrows"
            @update:checked="(checked) => $emit('toggleHelperArrows', checked)"
          />
          <Switch
            label="Skip Animations"
            :checked="skipAnimations"
            @update:checked="
              (checked) => $emit('toggleSkipAnimations', checked)
            "
          />
          <ExpandableContent showInitial v-if="!quizModeActive">
            <template v-slot:header>Cube Nets</template>

            <div className="menuitem-cubenet" v-for="i in 11" :key="i">
              <span>{{ i }}</span>

              <img
                :src="`nets/net_${i}.svg`"
                :alt="`select ${i}`"
                @click="onSelectNet(i)"
              />
            </div>
            <div className="menuitem-cubenet" v-for="i in 12" :key="i">
              <span>Invalid {{ i }}</span>

              <img
                :src="`nets/no_net_${i}.svg`"
                :alt="`select invalid ${i}`"
                @click="onSelectNet(i + 100, true)"
              />
            </div>
          </ExpandableContent>
        </div>
        <div class="sidebar-footer">
          Made with ❤️ by Simon Ott and Patrick Wissiak<br />
          <a href="mailto:admin@cube-nets.ch">admin@cube-nets.ch</a>
        </div>
      </div>
    </div>
    <img
      src="../assets/burger-menu.svg"
      :width="50"
      class="hamburger"
      alt="hamburger menu icon"
      @click="openSidebar"
    />
  </div>
</template>

<script>
import ExpandableContent from "./ExpandableContent.vue";
import Switch from "./SwitchComponent.vue";

export default {
  name: "NavSidebar",
  components: { ExpandableContent, Switch },
  props: {
    showHelperArrows: Boolean,
    skipAnimations: Boolean,
    quizModeActive: Boolean,
  },
  data() {
    return {
      isSidebarActive: false,
      listener: null,
    };
  },
  unmounted() {
    document.removeEventListener("click", this.listener);
  },
  methods: {
    onSelectNet(i) {
      this.$emit("showNet", i - 1);
      this.closeSidebar();
    },
    closeSidebar() {
      this.isSidebarActive = false;
      document.removeEventListener("click", this.listener);
    },
    openSidebar() {
      this.isSidebarActive = true;

      // don't fire the close function immediately
      setTimeout(() => {
        const el = document.querySelector(".sidebar");
        const that = this;
        this.listener = function (event) {
          if (!(el === event.target || el.contains(event.target))) {
            that.closeSidebar();
          }
        };
        document.addEventListener("click", this.listener);
      }, 200);
    },
  },
};
</script>

<style scoped>
.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  transform: translateX(0px);
  opacity: 1;
  visibility: visible;
  width: calc(3rem * 6);
  height: 100%;
  background-color: #ffffff;
  color: #121212;
  border-right: 3px solid #dadce0;
  transition: 0.2s ease-in-out;
  z-index: 99;
}

.sidebar-hidden {
  transform: translateX(-300px);
  opacity: 0;
  visibility: hidden;
}

.sidebar-header {
  box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.4);
}

.sidebar-content {
  display: flex;
  flex-direction: column;
  flex-basis: auto;
  flex-wrap: nowrap;
  height: 100%;
  min-height: 100%;
}

.sidebar-body {
  overflow: auto;
  padding: 10px 5px 10px 5px;
  margin: 20px 0 20px 0;
  flex: 1;
}

.sidebar-item {
  color: #52595f;
  border: 1px solid transparent;
  border-radius: 8px;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  padding: 0.5rem;
  margin-bottom: 0.5rem;
}

.sidebar-footer {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  padding: 10px 15px;
  font-size: 12px;
}
.hamburger {
  z-index: 2;
  position: fixed;
  top: 16px;
  left: 16px;
  cursor: pointer;
}
#titel-bar {
  margin: 16px 0 16px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
#titel-bar img {
  width: 100px;
  margin-right: 16px;
  display: inline-block;
}
#titel-bar h1 {
  margin: 30px 16px 30px 0;
  display: inline-block;
}

.menuitem-cubenet {
  cursor: pointer;
  text-align: center;
  margin: 20px;
  border-style: solid;
  border-color: #eee;
  padding: 10px;
}

.menuitem-cubenet:hover {
  background-color: #eee;
}

.menuitem-cubenet span {
  text-align: left;
  display: block;
}
.menuitem-cubenet img {
  margin-top: 10px;
  margin-bottom: 10px;
  max-width: 100%;
  max-height: 100px;
}
</style>
