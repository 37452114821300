import ARHandler from "./ARHandler.js";

export default {
  data: () => ({
    cameraEnabled: false,
    imageBitmap: null,
    track: undefined,
    imageCapture: undefined,
    renderer: null,
    rootCamera: null,
    prevLookAt: new Float32Array(3),
    prevPosition: new Float32Array(3),
    unsuccessfulCounter: 0,
  }),
  created() {
    this.initializeCam();
    this.arHandler = new ARHandler(this.updateCorners, this.updateCam);
    this.arHandler.init();
  },

  methods: {
    initializeCam() {
      this.prevLookAt[0] = 0;
      this.prevLookAt[1] = 0;
      this.prevLookAt[2] = 0;
      this.prevPosition[0] = 10;
      this.prevPosition[1] = 10;
      this.prevPosition[2] = 10;
    },
    updateCorners(corners) {
      if (!this.currentImage) {
        return;
      }
      const context = this.$refs.background.getContext("2d");
      context.font = "bold 20px Arial";
      const size = window.innerHeight;
      context.drawImage(this.currentImage, 0, 0, size, size);
      this.currentImage.close();
      if (!corners) {
        return;
      }
      if (window.location.hostname === "localhost") {
        for (let i = 0; i < corners.length / 2; i++) {
          const posX = ((corners[i * 2] + 1) / 2) * size;
          const posY = ((corners[i * 2 + 1] + 1) / 2) * size;
          context.beginPath();
          context.arc(posX, posY, 1, 0, 2 * Math.PI, false);
          context.lineWidth = 5;
          context.strokeStyle = "#FF0000";
          context.fillStyle = "red";
          context.stroke();
          context.fillText(i, posX, posY + 20);
        }
      }
      this.renderer.render(this.instance.proxy.$scene, this.rootCamera);
    },
    updateCam(params, net, success) {
      if (net % 11 !== this.net) {
        // model returns 0-10 for non-mirrored cube nets and 11-21 for mirrored cube nets
        this.showNet(net % 11, net > 10);
      }
      if (success === 1) {
        this.unsuccessfulCounter = 0;
      } else {
        this.unsuccessfulCounter++;
      }
      if (this.unsuccessfulCounter < 10) {
        this.prevPosition[0] = params[0];
        this.prevPosition[1] = params[1];
        this.prevPosition[2] = params[2];
        this.prevLookAt[0] = params[3];
        this.prevLookAt[1] = params[4];
        this.prevLookAt[2] = params[5];
      } else {
        // reset camera parameters to not get stuck in a bad state
        this.initializeCam();
        this.unsuccessfulCounter = 0;
      }

      this.rootCamera.position.set(params[0], params[1], params[2]);
      this.rootCamera.lookAt(params[3], params[4], params[5]);
      this.renderer.render(this.instance.proxy.$scene, this.rootCamera);
    },
  },
};
