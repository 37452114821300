<template>
  <div className="animation-control">
    <img
      className="chevron"
      src="../assets/chevron.svg"
      alt="Previous"
      @click="$emit('prev')"
      :style="{
        opacity: disableAnimationControls || currentStep == 0 ? 0.3 : 1,
      }"
    />
    <p id="control-status">
      <span v-if="!disableAnimationControls">
        {{ currentStep }}&nbsp;/&nbsp;{{ numberOfSteps }}
      </span>
    </p>
    <img
      className="chevron"
      src="../assets/chevron.svg"
      alt="Next"
      @click="$emit('next')"
      :style="{
        opacity:
          disableAnimationControls || currentStep === numberOfSteps ? 0.3 : 1,
        rotate: '180deg',
      }"
    />
  </div>
</template>

<script>
export default {
  name: "AnimationController",
  props: {
    currentStep: Number,
    numberOfSteps: Number,
    onStepChange: Function,
    disableAnimationControls: Boolean,
  },
};
</script>

<style scoped>
.animation-control {
  position: absolute;
  font-size: 1.5em;
  bottom: 8vh;
  display: flex;
  flex-flow: row !important;
  height: 50px;
  padding: 10px;
  background-color: #fefefe;
  border-radius: 35px;
  left: 10%;
  right: 10%;
}

.animation-control button {
  margin: 0 15vw;
  padding: 0.5em;
  font-size: 1em;
}

.chevron {
  height: 50px;
  cursor: pointer;
}

#control-status {
  margin: 0 auto;
  align-self: center;
}

@media (max-width: 580px) {
  .animation-control {
    flex-flow: column;
  }
}
</style>
