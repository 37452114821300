<template>
  <div class="quiz-box">
    <p>Is this a valid cube net?</p>
    <div class="round-button yes" :disabled="hasAnswered">
      <div class="round-button-circle" @click="!hasAnswered && onAnswer(true)">
        Yes
      </div>
    </div>
    <div class="round-button no" :disabled="hasAnswered">
      <div class="round-button-circle" @click="!hasAnswered && onAnswer(false)">
        No
      </div>
    </div>
    <div class="round-button next" :disabled="!hasAnswered">
      <div class="round-button-circle" @click="hasAnswered && onNext()">
        Next
      </div>
    </div>
    <div class="round-button end">
      <div class="round-button-circle" @click="$emit('end')">End</div>
    </div>
    <p v-if="hasAnswered">
      Your answer was
      <span :style="{ color: lastCorrect ? 'green' : 'red' }">
        {{ lastCorrect ? "correct" : "incorrect" }}.
      </span>
    </p>
    <p v-if="hasAnswered">
      This is {{ net < 22 ? "a valid" : "an invalid" }} cube net.
    </p>
    <p>Correct: {{ nCorrect }}/{{ nQuestions }}</p>
  </div>
</template>

<script>
export default {
  name: "QuizComponent",
  props: {
    net: Number,
  },
  data: () => ({
    lastCorrect: undefined,
    hasAnswered: false,
    nQuestions: 0,
    nCorrect: 0,
  }),
  methods: {
    onNext() {
      this.hasAnswered = false;
      this.$emit("change-net");
    },
    onAnswer(answer) {
      this.nQuestions++;
      if (this.net < 22 && answer === true) {
        this.nCorrect++;
        this.lastCorrect = true;
      } else if (this.net >= 100 && answer === false) {
        this.nCorrect++;
        this.lastCorrect = true;
      } else {
        this.lastCorrect = false;
      }
      this.hasAnswered = true;
      this.$emit("enable-controls");
    },
  },
};
</script>

<style scoped>
/* https://stackoverflow.com/a/38321027/6281103 */
.quiz-box {
  position: absolute;
  min-width: 230px;

  padding: 10px;
  top: 0;
  right: 0;
  border-radius: 0 0 0 35px;
  background-color: #f0f0f0;
}

.round-button {
  border-radius: 50%;
  margin-right: 10px;
  width: 45px;
  line-height: 45px;
}
.round-button-circle {
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  border-radius: 50%;

  box-shadow: 0 0 3px gray;
}
.yes {
  background: #66bb6a;
}
.yes:hover {
  background: #43a047;
  cursor: pointer;
}
.no {
  background: #ef5350;
}
.no:hover {
  background: #e53935;
}
.next {
  background: #5c6bc0;
}
.next:hover {
  background: #3949ab;
}
.end {
  background: #e0e0e0;
}
.end:hover {
  background: #bdbdbd;
}
.round-button[disabled="true"] {
  opacity: 0.3;
  cursor: not-allowed;
}
.round-button:hover {
  cursor: pointer;
}
.round-button {
  display: inline-block;

  text-align: center;
  color: #ffffff;
  font-size: 1.2em;
}
</style>
